import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import logo from "./assets/images/fortpixel-logo.png";
import "./App.css";
import { Login } from "./components/Login";
import { Dashboard } from "./components/Dashboard";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

function HomeRoute({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return children;
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <HomeRoute>
              <div className="min-h-screen flex flex-col items-center justify-between bg-black text-white">
                <div className="flex-grow flex flex-col items-center justify-center">
                  <div className="mb-8">
                    <img
                      src={logo}
                      alt="FortPixel Studios Logo"
                      className="mx-auto"
                    />
                  </div>
                  <h1 className="text-4xl font-bold mb-4 text-fortpixel-gold">
                    Coming Soon
                  </h1>
                  <Link
                    to="/login"
                    className="mt-4 px-6 py-2 bg-fortpixel-gold hover:bg-yellow-600 text-black font-medium rounded-md"
                  >
                    Admin Login
                  </Link>
                </div>
                <footer className="w-full text-center py-4">
                  <p className="text-sm text-gray-400">
                    © {new Date().getFullYear()} FortPixel Studios. All rights
                    reserved.
                  </p>
                </footer>
              </div>
            </HomeRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
