import React from "react";

const JsonModal = ({ data, onClose }) => {
  // Format the data to match the desired output
  const formatData = (data) => {
    // Filter out the placeholder if it exists
    const filteredData = Object.fromEntries(
      Object.entries(data || {}).filter(([key]) => key !== "__PLACEHOLDER__")
    );

    // Add custom formatting with 2 spaces indentation
    return `{\n${Object.entries(filteredData)
      .map(([key, value]) => `  "${key}": "${value}"`)
      .join(",\n")}\n}`;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(formatData(data));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg max-w-4xl w-full">
        <h2 className="text-xl font-bold mb-4 text-white">JSON Data</h2>
        <pre className="bg-gray-800 p-4 rounded overflow-auto max-h-96 text-white font-mono">
          {formatData(data)}
        </pre>
        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={handleCopy}
            className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
          >
            Copy to Clipboard
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default JsonModal;
