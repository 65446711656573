import React, { useState } from "react";

const BulkAddModal = ({ onAdd, onClose }) => {
  const [input, setInput] = useState("");

  const handleSubmit = () => {
    onAdd(input);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg max-w-2xl w-full">
        <h2 className="text-xl font-bold mb-4 text-white">Bulk Add Items</h2>
        <p className="text-gray-300 mb-4">Enter one item per line:</p>
        <textarea
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="w-full h-64 p-4 rounded bg-gray-800 text-white font-mono"
          placeholder="Item 1&#13;&#10;Item 2&#13;&#10;Item 3"
        />
        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
          >
            Add Items
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkAddModal;
