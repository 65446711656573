import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth, isUserAdmin } from "../firebase";
import DataManager from "./dashboard/DataManager";

export function Dashboard() {
  const navigate = useNavigate();
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (auth.currentUser) {
        const adminStatus = await isUserAdmin(auth.currentUser.uid);
        setIsApproved(adminStatus);
      }
    };

    checkAdminStatus();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkAdminStatus();
      } else {
        setIsApproved(false);
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <nav className="bg-gray-900 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold text-fortpixel-gold">Dashboard</h1>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <div
                className={`w-2 h-2 rounded-full ${
                  isApproved ? "bg-blue-500" : "bg-gray-500"
                }`}
              ></div>
              {isApproved && <span className="text-sm">Admin</span>}
            </div>
            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 rounded-md"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
      <div className="max-w-7xl mx-auto p-6">
        <h2 className="text-2xl font-bold mb-4">Tycoon Requires</h2>
        <DataManager />
      </div>
    </div>
  );
}
