import React from "react";

const DuplicateModal = ({ duplicates, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-bold mb-4 text-white">
          {duplicates.length > 0
            ? "Duplicate Names Found"
            : "No Duplicates Found"}
        </h2>
        {duplicates.length > 0 ? (
          <div className="space-y-4">
            {duplicates.map(([name, ids], index) => (
              <div key={index} className="bg-gray-800 p-4 rounded">
                <p className="text-white font-semibold mb-2">Name: {name}</p>
                <p className="text-gray-300">Duplicate IDs: {ids.join(", ")}</p>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-300 mb-4">
            No duplicate names were found in the current project.
          </p>
        )}
        <button
          onClick={onClose}
          className="mt-4 w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DuplicateModal;
